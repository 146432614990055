import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import { fechgastenprofiel } from "../Reducers/actions";

const GastenprofielPage = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const categorien = useSelector((state) => state.gastenverbruik.gastenprofielen);
  useEffect(() => {
    dispatch(fechgastenprofiel()).then(() => setLoading(false)).catch(() => setLoading(false));
  }, [dispatch]);

  const columns = [
    {
      dataField: "naam",
      text: "Naam",
    },
    {
      dataField: "_id",
      text: "Toevoegen",
      formatter: (cell, row) => (
        <Link className="btn btn-warning" to={`/gastenverbruik/gastenprofiel/edit/${row._id}`}>
          Wijzigen
        </Link>
      ),
      headerStyle: { width: "450px" },
    },
  ];

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Gasten Profiel</h3>
        <div className="box-tools pull-right">
          <Link className="btn btn-primary btn-sm" to="/gastenverbruik/gastenprofiel/insert">
            <i className="fa fa-plus"></i>&nbsp; Gastenprofiel Toevoegen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-body">
                {loading ? (
                  <p>Laden...</p>
                ) : (
                  <BootstrapTable
                    wrapperClasses="table-responsive-md"
                    keyField="_id"
                    data={categorien || []}
                    columns={columns}
                    pagination={paginationFactory()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GastenprofielPage;