import React, { useState, useEffect, useCallback } from "react";
import { Field, reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Rodal from "rodal";
import { required } from "redux-form-validators";
import { renderField } from "../../../Components/Forms/renders";
import { getarrangementen } from "../../../Pages/Arrangementen/Reducers/actions";
import SelectBox from "../../../Components/Forms/selectbox";

const DatumWijzigen = ({ handleSubmit, ervaring, arrangementen, deelnemers }) => {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [tijdsloten, setTijdsloten] = useState([]);
  const [datum, setDatum] = useState(null);
  const [deelnemersaantal, setDeelnemersaantal] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getarrangementen(ervaring.arrangementdatum.ervaring._id))
      .then(() => {
        setLoading(false);
        setDeelnemersaantal(deelnemers.reduce((acc, slot) => acc + parseInt(slot.aantal ?? 0, 10), 0));
      })
      .catch(() => setLoading(false));
  }, [dispatch, ervaring, deelnemers]);

  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);
  const datumOpties = arrangementen.ervaringdatums?.map((datum) => ({
    _id: datum._id,
    datum: moment(datum.datum).format("DD-MM-YYYY"),
  }));
  const nieuwedatum = useSelector((state) => state.form.changedatum?.values?.nieuwedatum);

  useEffect(() => {
    if (nieuwedatum) {
      const adatum = arrangementen.ervaringdatums.find((datum) => datum._id === nieuwedatum)?.beschikbaarheid;
      setTijdsloten(adatum.tijdssloten);
      setDatum(adatum);
    }
  }, [nieuwedatum]);
  return (
    <>
      <div className="row">
        <div className="col-sm-5">
          <h6 className="mb-0">Datum wijzigen</h6>
        </div>
        <div className="col-sm-7 text-secondary">
          <button type="button" className="btn btn-success" onClick={openModal}>
            <i className="fa-solid fa-calendar"></i> Datum Wijzigen
          </button>
        </div>
      </div>

      <Rodal visible={visible} onClose={closeModal} className="rodal-md">
        <div className="card actionmodal">
          <div className="card-header">
            <h4 className="mb-0">Datum wijzigen</h4>
          </div>
          <div className="box box-default">
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <Field name="nieuwedatum" type="text" inputnaam={"datum"} inputvalue={"_id"} options={datumOpties} component={SelectBox} label="Kies een datum" validate={required({ msg: "Datum is verplicht" })} />
                <Field name="nieuwetijdslot" type="text" inputnaam={"tijd"} inputvalue={"tijd"} options={tijdsloten} component={SelectBox} label="Kies een tijd" validate={required({ msg: "Voornaam is verplicht" })} />
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary" onClick={closeModal}>
                  Wijzigen
                </button>
              </div>
            </form>
            <div className="mt-4">
              <h5>Beschikbaarheid</h5>
              <ul className="list-group">
                {datum?.tijdssloten?.map((slot) => {
                  const max = slot.max ?? 0;
                  const personen = slot.aantalpersonen ?? 0;
                  const beschikbaar = max - personen;
                  const voldoendeRuimte = beschikbaar >= deelnemersaantal;

                  return (
                    <li key={slot._id} className={`list-group-item d-flex justify-content-between align-items-center ${!voldoendeRuimte ? "list-group-item-danger" : beschikbaar <= 5 ? "list-group-item-warning" : ""}`}>
                      <span>{slot.tijd}</span>
                      <span>
                        {slot.aantalreserveringen ? `${slot.aantalreserveringen} reservering(en), ${personen} personen – ${beschikbaar} beschikbaar` : `Geen reserveringen – ${max} beschikbaar`}
                        {!voldoendeRuimte && <strong className="text-danger ms-2">✖ Onvoldoende plek</strong>}
                        {voldoendeRuimte && beschikbaar < 5 && <span className="text-warning ms-2">⚠ Bijna vol</span>}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </Rodal>
    </>
  );
};

const ReduxFormDatumWijzigen = reduxForm({
  form: "changedatum",
})(DatumWijzigen);

const mapStateToProps = (state) => ({
  arrangementen: state.arrangementen.item,
});

export default connect(mapStateToProps, {})(ReduxFormDatumWijzigen);
