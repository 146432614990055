import React, {  } from "react";
import { Field, reduxForm } from "redux-form";
import { required, numericality } from "redux-form-validators";
import { renderField } from "../../../../../Components/Forms/renders";


const AddKWHForm = ({ handleSubmit }) => {
  return (
      <form onSubmit={handleSubmit} className="kwh-settings p-2 mt-4">
        <h4 className="m-0">Extra bij kopen</h4>

        <Field name="kwh" component={renderField} type="number" label="Aantal kWh instellen" validate={[required(), numericality()]} />

        <div className="box-footer">
          <button type="submit" className="btn btn-primary">
            Verzenden
          </button>
        </div>
      </form>
  );
};

export default reduxForm({
  form: "addkwh",
})(AddKWHForm);
