import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";

const getNestedObjectValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const AutosugformApi = ({ sendsuggestion, input, label, suggestievalue, suggestieid, url, minval, meta: { touched, error, warning }, inputvalue }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (input.value) {
      const initialValue = getNestedObjectValue(input.value, suggestievalue);
      if (initialValue) {
        setValue(initialValue);
      }
    }
  }, [input.value, suggestievalue]);

  const onChange = (event, { newValue }) => {
    setValue(newValue || "");
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    if (sendsuggestion) sendsuggestion(suggestion);
    input.onChange(getNestedObjectValue(suggestion, suggestieid) || "");
  };

  const clearInput = () => {
    input.onChange({});
    setValue("");
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length < minval) {
      return false;
    }
    if (inputvalue) inputvalue(value);
    const response = await fetch(url, {
      method: "post",
      body: JSON.stringify({ query: value }),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    const data = await response.json();
    setSuggestions(data.results);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => getNestedObjectValue(suggestion, suggestievalue) || "";

  const renderSuggestion = (suggestion) => <div>{getNestedObjectValue(suggestion, suggestievalue)}</div>;

  const inputProps = {
    placeholder: "Maak een keuze",
    value,
    onChange,
  };

  return (
    <div>
      <label>{label}</label>
      <div className="inputs">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          className="form-control"
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
        />
        <span onClick={clearInput} id="searchclear" className="usericonbox glyphicon glyphicon-remove-circle"></span>
      </div>
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  );
};

export default AutosugformApi;
