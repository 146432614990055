import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Rodal from "rodal";
import { addgastengebruik, addgasten } from "./Reducers/actions";
import { fetchkampeerplaatsen, fetchaccommodaties } from "../Standplaatsen/Reducers/actions";
import InsertForm from "./Components/insert";
import Insertgast from "./Components/insertgast";

const KampeerplaatsEditPage = ({ fetchaccommodaties, addgastengebruik, kampeerplaatsen, fetchkampeerplaatsen, addgasten, accommodaties, bedrijf }) => {
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [gastid, setGastId] = useState(null);
  const [emailadres, setEmailadres] = useState("");
  const [gastdata, setGastdata] = useState(null);

  const navigate = useNavigate();
  const submit = (vals) => {
    delete vals["data"];
    addgastengebruik(vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        return false;
      } else {
        navigate("/gastenverbruik/");
      }
    });
  };
  const submitgast = (vals) => {
    addgasten(vals).then((data) => {
      if (data.type === "GASTEN_VERBRUIK_ERROR") {
        alert("Er is een fout opgetreden");
        return false;
      } else {
        setGastId(data.results.result);
        setVisible(false);
      }
    });
  };
  const OpenmMdal = (val) => {
    setVisible(val);
  };
  useEffect(() => {
    fetchkampeerplaatsen().then(() => {
      fetchaccommodaties().then(() => {
        setLoaded(true);
      });
    });
  }, [loaded]);
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Reservering toevoegen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">
              {loaded && <InsertForm setGastdata={(value) => setGastId(value)}  emailadres={(value) => setEmailadres(value)} bedrijf={bedrijf} gast={gastid} onSubmit={submit} standplaatsen={kampeerplaatsen} accommodaties={accommodaties} OpenmMdal={OpenmMdal} />}
            </div>
          </div>
        </div>
      </div>
      <Rodal
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        className="rodal-md"
      >
        <div className="box box-default">
          <div className="row">
            <div className="col-md-12  col-sm-12">
              <div className="box-title">
                <h3>Maak een nieuwe gast aan</h3>
              </div>

              <div className="box-body">
                <Insertgast onSubmit={submitgast} emailadres={emailadres} />
              </div>
            </div>
          </div>
        </div>
      </Rodal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bedrijf: state.auth.user.bedrijf,
  kampeerplaatsen: state.standplaatsen.kampeerplaatsen,
  accommodaties: state.standplaatsen.accommodaties,
});

export default connect(mapStateToProps, { addgastengebruik, fetchkampeerplaatsen, addgasten, fetchaccommodaties })(KampeerplaatsEditPage);
