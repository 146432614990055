import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetshsoortsensoren, getkampeerplaats, updatekampeerplaats, fetchstanplaatscategorien, fetchrelais, fetchveldkasten } from "../Reducers/actions";

import BedrijvenForm from "./Components/edit";

const KampeerplaatsInsert = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const soortsensoren = useSelector((state) => state.standplaatsen.sensoren);
  const categorien = useSelector((state) => state.standplaatsen.categorien);
  const relais = useSelector((state) => state.standplaatsen.relaiss);
  const veldkasten = useSelector((state) => state.standplaatsen.veldkasten);
  const submit = (vals) => {
    dispatch(updatekampeerplaats(params.id, vals)).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/standplaatsen/kampeerplaatsen");
      }
    });
  };

  useEffect(() => {
    dispatch(getkampeerplaats(params.id)).then(() => {
      dispatch(fetshsoortsensoren({ soort: "electra" })).then(() => {
        dispatch(fetchrelais()).then(() => {
          dispatch(fetchstanplaatscategorien()).then(() => {
            dispatch(fetchveldkasten()).then(() => {
              setLoaded(true);
            });
          });
        });
      });
    });
  }, [dispatch, params.id]);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Kampeerplaats wijzigen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BedrijvenForm categorien={categorien} veldkasten={veldkasten} sensoren={soortsensoren} relais={relais} onSubmit={submit} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KampeerplaatsInsert;
