import React from "react";
import { Field, reduxForm } from "redux-form";
import { required, numericality } from "redux-form-validators";
import { renderselectapi } from "../../../../../Components/Forms/renders";
import SelectBox from "../../../../../Components/Forms/selectbox";

const VerplaatsenBoeking = ({ handleSubmit, plaatsen }) => {
  const plaatsenzonderreservering = plaatsen.filter((plaats) => !plaats?.sensor?.boeking?._id);
  return (
    <form onSubmit={handleSubmit} className="kwh-settings p-2 mt-4">
      <h4 className="m-0">Reservering Verplaatsen</h4>

      <Field name="plaats" component={SelectBox} options={plaatsenzonderreservering} inputnaam="naam" inputvalue="_id" type="input"  label="Kies een plaats" validate={[required()]} />

      <div className="box-footer">
        <button type="submit" className="btn btn-primary">
          Verzenden
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "verplaatsboekingform",
})(VerplaatsenBoeking);
