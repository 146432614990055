import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import HeaderFinancieel from "./Components/header";
import { useParams, useNavigate } from "react-router-dom";

import { fetchonemetreservering } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
import { usePDF } from "react-to-pdf";
const FinancieelBoekingen = ({ fetchonemetreservering, gast, username }) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [datum, setDatum] = useState({
    startdate: moment().startOf("year").toDate(),
    enddate: moment().toDate(),
  });

  const { toPDF, targetRef } = usePDF({ filename: "Boekingen.pdf" });
  const params = useParams();
  useEffect(() => {
    fetchonemetreservering(params.id, datum).then((sdata) => {
      setLoaded(true);
    });
  }, []);

  const changedata = (vals) => {
    if (vals.datum) {
      fetchonemetreservering({ datum: datum }).then((sdata) => {
        if (sdata.results) {
          setLoaded(true);
        }
      });
    }
  };

  const totaalverbruik = gast?.reserveringen?.reduce((acc, boeking) => {
    if (boeking?.status === "checkout" && boeking?.meterstandvertrek != null && boeking?.meterstanndaankomst != null) {
      return acc + (boeking.meterstandvertrek - boeking.meterstanndaankomst);
    }
    return acc;
  }, 0);
  console.log(gast);
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <HeaderFinancieel changedata={changedata} />
        </div>
      </div>

      {loaded && (
        <div className="box-body box-profile">
          <div className="box-header with-border d-flex">
            <div className="box-body" ref={targetRef}>
              <div className="container">
                <div className="card shadow-lg">
                  {/* Invoice Header */}
                  <div className="card-header text-center bg-green text-white">
                    <h3 className="mb-0">Reserveringen </h3>
                  </div>

                  {/* From/To Section */}
                  <div className="card-body">
                    <div className="row m-4">
                      <div className="col-md-6">
                        <h5 className="mb-3 fw-bold">Van</h5>
                        <div className="ps-3 border-start border-2 border-green">
                          <p className="mb-1">{username.bedrijf.naam}</p>
                          <p className="mb-1">{username.bedrijf.straat}</p>
                          <p className="mb-1">
                            {username.bedrijf.postcode} {username.bedrijf.plaats}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 text-md-end">
                        <h5 className="mb-3 fw-bold">Aan</h5>
                        <div className="pe-3 border-end border-2 border-green">
                          {(() => {
                            const owner = gast;

                            return (
                              <>
                                <p className="mb-1">
                                  {owner?.voornaam} {owner?.tussennaam} {owner?.achternaam}
                                </p>
                                {owner.bootnaam && <p className="mb-1">{owner.bootnaam}</p>}
                                <p className="mb-1">{owner?.gastenprofiel.naam || "Onbekend"}</p>
                                <p className="mb-1">{owner?.email || "Onbekend"}</p>
                              </>
                            );
                          })()}
                        </div>
                      </div>
                      <div className="col-12">
                        <h5 className="mb-3 fw-bold">Datum</h5>
                        <div className="ps-3 border-start border-2 border-green">
                          <p className="mb-1">
                            van {moment(datum.startdate).format("DD-MM-YYYY")} -ot {moment(datum.enddate).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Table Section */}
                    <div className="table-responsive m-4">
                      <table className="table table-hover table-bordered">
                        <thead className="table-light">
                          <tr>
                            <th>Aankomst</th>
                            <th>Vertrek</th>
                            <th>Status</th>
                            <th>Verbruik</th>
                            <th className="text-end"> Totaal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(gast.reserveringen) && gast.reserveringen.length > 0 ? (
                            gast.reserveringen.map((boeking) => (
                              <tr key={boeking._id} onClick={() => navigate(`/gastenverbruik/kampeerplaats/${boeking._id}`)} style={{ cursor: "pointer" }}>
                                <td>{moment(boeking.aankomst).format("DD-MM-YYYY")}</td>
                                <td>{boeking.vertrek && moment(boeking.vertrek).format("DD-MM-YYYY")}</td>

                                <td>{boeking?.status === "checkout" ? "Vertrokken" : boeking?.status === "nobalance" ? "Geen saldo" : "Aanwezig"}</td>
                                <td>{boeking?.status != "checkout" || boeking?.meterstandvertrek == null || boeking?.meterstanndaankomst == null ? "0.00" : (boeking.meterstandvertrek - boeking.meterstanndaankomst).toFixed(2)}</td>
                                <td className="text-end">
                                  {(() => {
                                    const verbruik = boeking?.status != "checkout" || boeking?.meterstandvertrek == null || boeking?.meterstanndaankomst == null ? "0.00" : (boeking.meterstandvertrek - boeking.meterstanndaankomst).toFixed(2);
                                    const prijs = parseFloat(boeking.standplaats.categorie.prijs) || 0;

                                    // Controleer op refund en bereken het juiste bedrag
                                    const bedrag = verbruik * prijs;

                                    return bedrag.toLocaleString("nl-NL", {
                                      style: "currency",
                                      currency: "EUR",
                                    });
                                  })()}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center text-muted">
                                Geen boekingen beschikbaar
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <table className="table table-striped table-bordered">
                        <tbody>
                          <tr>
                            <td>Subtotal:</td>
                            <td className="text-end"></td>
                          </tr>
                          <tr className="fw-bold">
                            <td>Total:</td>
                            <td className="text-end">
                              {typeof totaalverbruik === "number" && !isNaN(totaalverbruik)
                                ? totaalverbruik.toLocaleString("nl-NL", {
                                    style: "currency",
                                    currency: "EUR",
                                  })
                                : "€ 0,00"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    \
                  </div>

                  {/* Footer */}
                  <div className="card-footer bg-light text-center">
                    <p className="mb-0">Bedankt voor de samenwerking!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer">
            <button className="btn btn-primary" onClick={toPDF}>
              Download PDF
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, gast: state.gastenverbruik.gast };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  fetchonemetreservering,
})(FinancieelBoekingen);
