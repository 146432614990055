import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getsensordevice_id, setupsensor, getsensorstatus } from "../../Sensors/Reducers/actions";

import BedrijvenForm from "./Components/wijzigen";

const KampeerplaatsWijzigenPage = () => {
  const [loaded, setLoaded] = useState(false);
  const [loadstatus, setLoadstatus] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const submit = (vals) => {
    dispatch(setupsensor(vals._id, vals)).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/standplaatsen/kampeerplaatsen");
      }
    });
  };

  useEffect(() => {
    dispatch(getsensordevice_id(params.id)).then(() => {
      setLoaded(true);
    });
  }, [dispatch, params.id]);

  const getstatus = () => {
    setLoadstatus("We halen de status op van de meter"); // Zorg ervoor dat de status niet blijft hangen

    dispatch(getsensorstatus(params.id, { payload: "AQMAgAABheI=", field: "status" }))
      .then((data) => {
        if (data.type === "SENSOR_ERROR") {
          alert(data.payload);
          setLoadstatus("We halen de status op van de meter"); // Zorg ervoor dat de status niet blijft hangen
          return;
        }
        setLoadstatus("We halen de ampere status op van de meter"); // Zorg ervoor dat de status niet blijft hangen
        return dispatch(getsensorstatus(params.id, { payload: "AQMAhgABZeM=", field: "amperestatus" }));
      })
      .then((data) => {
        if (data && data.type === "SENSOR_ERROR") {
          alert(data.payload);
        }
        setLoadstatus(false);
      })
      .catch((error) => {
        console.error("Fout bij het ophalen van de status:", error);
        alert("Er is een fout opgetreden bij het ophalen van de status.");
        setLoadstatus(false);
      });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Meter instellen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BedrijvenForm onSubmit={submit} getstatus={getstatus} />}</div>
            {loadstatus && (
              <div className="overlay">
                <div className="m-4">
                  <i className="fa fa-refresh fa-spin fa-2x"></i> {loadstatus}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KampeerplaatsWijzigenPage;
