import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { fetchasten } from "../Reducers/actions";

const InschrijvingPage = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const gastenverbruik = useSelector((state) => state.gastenverbruik.gasten);
  const { SearchBar } = Search;
  useEffect(() => {
    getVals();
  }, []);

  const getVals = async () => {
    const data = await dispatch(fetchasten());
    if (data.type !== "ACTIVITEIT_ERROR") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "voornaam",
      text: "Naam gast",
      formatter: (cellContent, row) => {
        return (
          <span>
            {row.voornaam} {row.tussennaam} {row.achternaam}
          </span>
        );
      },
    },
    {
      dataField: "bootnaam",
      text: "Bootnaam",
    },
    {
      dataField: "email",
      text: "E-Mail",
    },
    {
      dataField: "gastenprofiel.naam",
      text: "Profiel",
    },

    {
      dataField: "reserveringen",
      text: "Aantal reserveringen",
      formatter: (cellContent, row) => {
        return <span>{row.reserveringen.length}</span>;
      },
    },

    {
      text: "Acties",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <Link className="btn btn-warning btn-sm mx-2" to={{ pathname: "/gastenverbruik/gasten/edit/" + row._id }}>
              Wijzigen
            </Link>
            <Link className="btn btn-primary btn-sm" to={{ pathname: "/gastenverbruik/gasten/" + row._id }}>
              Bekijken
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "250px" };
      },
    },
  ];
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Gasten</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-warning btn btn-box-too btn-sm mx-2" to="/gastenverbruik/gasten/upload">
            <i className="fa fa-plus"></i>&nbsp; Upload gasten
          </Link>
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/gastenverbruik/gasten/insert">
            <i className="fa fa-plus"></i>&nbsp; Gast Toevoegen
          </Link>
        </div>
      </div>
      {loading ? (
        <div className="box box-default">
          <div className="row">
            <div className="col-md-12 no-float">
              <div className="box box-primary">
                <div className="box-body box-profile" />
                <ToolkitProvider
                  keyField="_id"
                  data={gastenverbruik}
                  columns={columns}
                  search={{
                    searchFormatted: true,
                  }}
                >
                  {(props) => (
                    <div>
                      <SearchBar placeholder="Zoeken" {...props.searchProps} />
                      <hr />
                      <BootstrapTable wrapperClasses="table-responsive-md" pagination={paginationFactory()} {...props.baseProps} />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="alert alert-info mt-3">De gegevens worden geladen</div>
      )}
    </div>
  );
};
export default InschrijvingPage;
