import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import PropTypes from "prop-types";
import SwitcButton from "../../../../Components/Forms/switch";
import TimeSelect from "../../../../Components/Forms/time";

const SensorEditForm = ({ handleSubmit }) => {
  const requiredValidator = useMemo(() => required(), []);

  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam Gasten profiel" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving standplaats categorie (optioneel)" />
              <Field name="betalen" type="input" component={SwitcButton} label="Gasten moeten betalen voor stroom" />
              <Field name="instellenkwh" type="input" component={SwitcButton} label="Moet er een kWh worden ingesteld" />
              <Field name="prijs" type="number" component={renderField} label="Prijs per dag" />
              <Field name="minimaalkwh" type="number" component={renderField} label="Mimimaal aantal kWh per boeking" />
              <Field name="oprekening" type="input" component={SwitcButton} label="Gasten betalen op rekening (jaarplaatsen)" />
              <Field name="autouitcheck" type="input" component={SwitcButton} label="Gasten worden automatisch uitgechecked" />
              <Field name="minaantaldagen" type="input" component={renderField} label="Minimaal aantal dagen" />
              <Field name="uitchecktijd" type="number" component={TimeSelect} label="Tijd van uitchecken" />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Update" />
          </div>
        </div>
      </form>
    </div>
  );
};

SensorEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxFormSensorEdit = reduxForm({
  form: "gasteprofiel_edit", // a unique identifier for this form
})(SensorEditForm);

const mapStateToProps = (state) => ({
  initialValues: state.gastenverbruik.gastenprofiel, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
