import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderselect } from "../../../../Components/Forms/renders";

import PropTypes from "prop-types";

const WijzigKampeerplaatsen = ({ handleSubmit, getstatus }) => {
  const requiredValidator = useMemo(() => required(), []);
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field
                className="form"
                name="electra.ingeschakeld"
                validate={requiredValidator({ msg: "Type item is verplicht" })}
                label="Standplaats status"
                component={renderselect}
                options={{
                  true: "Actief",
                  false: "Uitgeschakeld",
                }}
              />
              <Field
                name="electra.ampere"
                type="number"
                validate={[required()]}
                component={renderselect}
                label="Ampere instellen"
                options={{
                  4: "4 Ampere",
                  6: "6 Ampere",
                  8: "8 Ampere",
                  10: "10 Ampere",
                  12: "12 Ampere",
                  14: "14 Ampere",
                  16: "16 Ampere",
                  18: "18 Ampere",
                  20: "20 Ampere",
                }}
              />
              <Field
                name="electra.uitschakelwaarde"
                type="number"
                validate={[required()]}
                component={renderselect}
                label="Ampere direct uitschalen instellen"
                options={{
                  4: "4 Ampere",
                  6: "6 Ampere",
                  8: "8 Ampere",
                  10: "10 Ampere",
                  12: "12 Ampere",
                  14: "14 Ampere",
                  16: "16 Ampere",
                  18: "18 Ampere",
                  20: "20 Ampere",
                }}
              />
              <Field
                name="electra.uitschakeltijd"
                type="number"
                validate={[required()]}
                component={renderselect}
                label="Ampere direct uitschalen instellen"
                options={{
                  5: "5 seconden",
                  10: "10 seconden",
                  15: "15 seconden",
                  20: "20 seconden",
                  30: "30 seconden",
                  45: "45 seconden",
                  60: "60 seconden",
                }}
              />
            </div>
          </div>
          <div className="d-flex gap-3 mt-3">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
            <input type="button" onClick={getstatus} className="btn btn-next btn-fill btn-info btn-wd btn-mg" name="status" value="Haal status op van de meter" />
          </div>
        </div>
      </form>
    </div>
  );
};

WijzigKampeerplaatsen.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const ReduxFormSensorEdit = reduxForm({
  form: "wijzig_meter_edit", // a unique identifier for this form
  enableReinitialize: true,
})(WijzigKampeerplaatsen);

const mapStateToProps = (state) => ({
  initialValues: state.sensoren.item, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
