import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderTextField } from "../../../../Components/Forms/renders";

const InsertForm = ({ handleSubmit }) => {
    const requiredValidator = useMemo(() => required(), []);
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field name="naam" type="input" validate={[requiredValidator]} component={renderField} label="Naam veldkast" />
              <Field name="beschrijving" type="input" component={renderTextField} label="Beschrijving veldkast (optioneel)" />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(
  reduxForm({
    form: "insertveldkast",
  })(InsertForm)
);
