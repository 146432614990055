import React, { useState } from "react";
import { connect } from "react-redux";
import { uploadgasten } from "../Reducers/actions";
import { useParams, useNavigate, Link } from "react-router-dom";
import GastenUploadForm from "../Components/upload";

const GastenUpload = ({ uploadgasten }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [results, setResults] = useState([]);
  const [noresults, setNoresults] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const submitgast = (vals) => {
    uploadgasten(vals).then((data) => {
      if (data.type === "GASTEN_VERBRUIK_ERROR") {
        setError(data?.error?.data?.global || data?.error?.message || "Er is een fout opgetreden");
        return false;
      } else {
        console.log(data.results);
        setResults(data?.results.results || []);
        setNoresults(data?.results.noresults || []);
        setError(false);
        setLoaded(true);
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Gasten uploaden</h3>
      </div>
      <div className="box box-default">
        <div className="box-body">
          <GastenUploadForm onSubmit={submitgast} />

          {error && (
            <div className="alert alert-danger mt-3">
              <strong>Fout:</strong> {error}
            </div>
          )}

          {loaded && (
            <>
              <div className="alert alert-success mt-3">
                <strong>Gelukt:</strong> {results.length} gasten toegevoegd
              </div>

              {results.length > 0 && (
                <div className="mt-3">
                  <h4>Toegevoegde gasten</h4>
                  <ul>
                    {results.map((gast, i) => (
                      <li key={i}>{gast.email || "zonder e-mail"}</li>
                    ))}
                  </ul>
                </div>
              )}

              {noresults.length > 0 && (
                <div className="mt-3">
                  <h4>Niet toegevoegd</h4>
                  <ul>
                    {noresults.map((gast, i) => (
                      <li key={i}>
                        {gast.email || "zonder e-mail"} — <em>{gast.reden}</em>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Link to="/gastenverbruik/gasten" className="btn btn-primary mt-3">
                Terug naar gasten
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  gast: state.gastenverbruik.gast,
});

export default connect(mapStateToProps, { uploadgasten })(GastenUpload);
