import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addgasten } from "../Reducers/actions";
import { useParams, useNavigate } from "react-router-dom";
import EditGastForm from "./Components/insert";

const KampeerplaatsEditPage = ({ addgasten }) => {
  const [loaded, setLoaded] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const submitgast = (vals) => {
    addgasten(vals).then((data) => {
      if (data.type === "GASTEN_VERBRUIK_ERROR") {
        alert("Er us een fout opgetreden");
        return false;
      } else {
        navigate("/gastenverbruik/gasten");
      }
    });
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title">Gast Toevoegen</h3>
      </div>
      <div className="box box-default">
        <div className="box-body">
          <EditGastForm onSubmit={submitgast} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  gast: state.gastenverbruik.gast,
});

export default connect(mapStateToProps, { addgasten })(KampeerplaatsEditPage);
