import React, { useEffect, useState } from "react";
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { required } from "redux-form-validators";
import { connect } from "react-redux";
import moment from "moment";
import SelectBox from "../../../Components/Forms/selectbox";
import DateTimeInput from "../../../Components/Forms/datetime";
import AutosugformApi from "../../../Components/Forms/autocompleteapi";
import switcbutton from "../../../Components/Forms/switch";

const BedrijvenForm = ({ handleSubmit, standplaatsen, formValues, OpenmMdal, accommodaties, gast, dispatch, bedrijf }) => {
  const [keuze, setKeuze] = useState("accommodatie");
  useEffect(() => {
    if (gast && formValues) {
      dispatch(change("gastenverbuikedit", "gast", gast));
    }
    if (formValues && formValues.keuze === "kampeerplaats") {
      if (bedrijf.modules.includes("kampeerplaatsen")) {
        dispatch(change("gastenverbuikedit", "keuze", "kampeerplaats"));
        setKeuze("kampeerplaats");
      } else if (bedrijf.modules.includes("accommodaties")) {
        dispatch(change("gastenverbuikedit", "keuze", "accommodatie"));
        setKeuze("accommodatie");
      }
    }
  }, [gast, formValues, dispatch, bedrijf.modules]);
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field name="gast" type="email" minval="3" suggestievalue="email" suggestieid="_id" url="/api/admin/stamgegevens/find" validate={required({ msg: "Dit item is verplicht" })} component={AutosugformApi} label="Zoek op gast e-mail" />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              {bedrijf.modules.includes("kampeerplaatsen") && (
                <label>
                  <Field name="keuze" component="input" type="radio" value="kampeerplaats" className="form-check-input mx-2" onChange={() => setKeuze("kampeerplaats")} />
                  verblijfsplaats
                </label>
              )}
              {bedrijf.modules.includes("accommodaties") && (
                <label>
                  <Field name="keuze" component="input" type="radio" value="accommodatie" className="form-check-input mx-2" onChange={() => setKeuze("accommodatie")} />
                  Accommodatie
                </label>
              )}
            </div>
            {keuze === "kampeerplaats" && <Field name="standplaats" type="input" options={standplaatsen} inputvalue="_id" inputnaam="naam" component={SelectBox} label="Kies de verblijfsplaats" />}
            {keuze === "accommodatie" && <Field name="accommodaties" type="input" options={accommodaties} inputvalue="_id" inputnaam="naam" component={SelectBox} label="Kies de accommodaties" />}

            <Field name="altijdtoegang" label="De gast heeft altijd toegang" type="input" component={switcbutton} />
            <Field name="aankomst" component={DateTimeInput} type="input" validate={required({ msg: "Dit item is verplicht" })} label="Aankomst datum verblijf" />
            <Field name="vertrek" component={DateTimeInput} type="input" validate={required({ msg: "Dit item is verplicht" })} label="Vertrek datum verblijf" />
          </div>
        </div>
        <div className="pull-left submit">
          <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("gastenverbuikedit")(state), // get current form values
  initialValues: state.gastenverbruik.gastverbruik, // pull initial values from account reducer
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "gastenverbuikedit",
  })(BedrijvenForm)
);
