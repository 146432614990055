import { Link } from "react-router-dom";
import React, { useState } from "react";
const SensorSidebar = ({ token, username }) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && username.bedrijf.modules.includes("sensoren")) {
    if (token.role === "admin" || token.role === "manager" || token.role === "medewerker") {
      function addsensor() {
        if (token.role === "admin" || token.role === "manager") {
          return (
            <li>
              <Link to="/sensoren/sensor/toevoegen" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-plus" />
                <span>Sensor toevoegen</span>
              </Link>
            </li>
          );
        }
      }

      function addprofile() {
        if (token.role === "admin" || token.role === "manager") {
          return (
            <>
              {" "}
              <li>
                <Link to="/sensoren/alertprofile" onClick={() => setIsActive(!isActive)}>
                  <i className="fa fa-edit" />
                  <span>Notificatie instellingen</span>
                </Link>
              </li>
              <li>
                <Link to="/sensoren/instellingen" onClick={() => setIsActive(!isActive)}>
                  <i className="fa fa-gear" />
                  <span>Sensor instellingen</span>
                </Link>
              </li>
            </>
          );
        }
      }

      return (
        <li className="treeview">
          <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
            <i className="fas fa-project-diagram"></i> <span>Sensoren</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "}>
            <li>
              <Link to="/sensoren/" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-search" />
                <span>Sensoren</span>
              </Link>
            </li>
            <li>
              <Link to="/sensoren/rapportage" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-chart-simple" />
                <span>Sensoren rapportage</span>
              </Link>
            </li>
            <li>
              <Link to="/sensoren/map" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-map" />
                <span>Sensoren op kaart</span>
              </Link>
            </li>
            {addsensor()}
            {addprofile()}
          </ul>
        </li>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export default SensorSidebar;
