import React from "react";
import { Field, reduxForm } from "redux-form";
import { required, numericality } from "redux-form-validators";
import DateTimeInput from "../../../../../Components/Forms/datetime";

const ChangeVertrek = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} className="kwh-settings p-2 mt-4">
      <h4 className="m-0">
        Vertrekdatum wijzigen
      </h4>

      <Field name="vertrek" minDate={new Date()} minTime={true} component={DateTimeInput} type="input" timeIntervals="15" label="Vertrek datum verblijf" validate={required({ msg: "Dit item is verplicht" })} />

      <div className="box-footer">
        <button type="submit" className="btn btn-primary">
          Verzenden
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "changevertrek",
})(ChangeVertrek);
