import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import { fetchveldkasten } from "../Reducers/actions";

const VeldkastPage = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const veldkasten = useSelector((state) => state.standplaatsen.veldkasten);

  useEffect(() => {
    getVals();
  }, []);

  const getVals = async () => {
    const data = await dispatch(fetchveldkasten());
    if (data.type !== "ACTIVITEIT_ERROR") {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "naam",
      text: "Naam veldkast",
    },
    {
      text: "Toevoegen",
      dataField: "_id",
      formatter: (cellContent, row) => {
        return (
          <span>
            <Link className="btn btn-warning" to={{ pathname: "/standplaatsen/veldkasten/edit/" + row._id }}>
              Wijzigen
            </Link>
          </span>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "450px" };
      },
    },
  ];
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Veldkast</h3>
        <div className="box-tools pull-right">
          <Link type="button" className="btn-primary btn btn-box-too btn-sm" to="/standplaatsen/veldkasten/insert">
            <i className="fa fa-plus"></i>&nbsp; veldkast Toevoegen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile" />
              <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={veldkasten} columns={columns} pagination={paginationFactory()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VeldkastPage;
