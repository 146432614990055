import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../Components/Parts/header";
import ActiviteitenWidget from "./Activiteiten/Components/widget";
import FaciliteitenWidget from "./Faciliteiten/Components/widget";
import SensorWidget from "./Sensors/Components/widget";
import SensorWidgetFavo from "./Sensors/Components/favowidget";
import VVVItemsWidget from "./Items/Components/widget";
import ErvaringenWidget from "./Ervaringen/Components/widget";
import BoekingenWidget from "./Boekingen/Components/widget";
import ErvaringenWidgetAankomende from "./Ervaringen/Components/widgetaankomende";
import FavSesnorenWidget from "./Standplaatsen/Kampeerplaatsen/Components/favowidgetMeter";
import FavAccoWidget from "./Standplaatsen/Accommodaties/Components/favowidget";
import { fetchsensoren } from "./Sensors/Reducers/actions";
import Loading from "../Components/loading/load";
const getVisibilityPreferences = () => {
  const preferences = localStorage.getItem("widgetVisibility");
  return preferences ? JSON.parse(preferences) : {};
};

const setVisibilityPreferences = (preferences) => {
  localStorage.setItem("widgetVisibility", JSON.stringify(preferences));
};

const Startpage = ({ user, sensoren, fetchsensoren, history }) => {
  const [load, setLoad] = useState(false);
  const [visibility, setVisibility] = useState(getVisibilityPreferences());

  useEffect(() => {
    fetchsensoren().then((data) => {
      setLoad(true);
    });
  }, [fetchsensoren]);

  useEffect(() => {
    const savedVisibility = getVisibilityPreferences();
    setVisibility(savedVisibility);
  }, []);

  const vaild = (vall) => {
    return vall.includes(user.role);
  };

  const ismodule = (val) => {
    return user.bedrijf && user.bedrijf.modules && user.bedrijf.modules.includes(val) && !visibility[val];
  };

  const toggleVisibility = (widget) => {
    const newVisibility = { ...visibility, [widget]: !visibility[widget] };
    setVisibility(newVisibility);
    setVisibilityPreferences(newVisibility);
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Welkom bij {user && user.bedrijf && user.bedrijf.naam}</h3>
        <div className="box-tools pull-right">
          <select className="form-control" onChange={(e) => toggleVisibility(e.target.value)}>
            <option value="">Widgets</option>
            <option value="kampeerplaatsen">Kampeerplaatsen</option>
            <option value="accommodaties">Accommodaties</option>
            <option value="sensoren">Sensoren</option>
            <option value="activiteiten">Activiteiten</option>
            <option value="faciliteiten">Faciliteiten</option>
            <option value="items">Items</option>
            <option value="ervaringen">Ervaringen</option>
          </select>
        </div>
      </div>
      {vaild(["admin", "manager", "hoofdrecreatie", "faciliteitmedewerker", "ervaringeigenaar"]) && <Header user={user} />}
      <div className="row">
      {vaild(["admin", "manager", "medewerker", "faciliteitmedewerker"]) && ismodule("sensoren") && sensoren.some((sensor) => sensor.favo === true) && (
          <div className="col-md-12">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Favoriete sensoren</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("sensoren")}>
                    X
                  </button>
                </div>
              </div>
              {load && <SensorWidgetFavo sensoren={sensoren} />}
              <Loading loadings={load} />
            </div>
          </div>
        )}

        {vaild(["admin", "manager", "medewerker", "faciliteitmedewerker"]) && ismodule("kampeerplaatsen") && (
          <div className="col-md-12">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Jachthavenplaatsen</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("kampeerplaatsen")}>
                    X
                  </button>
                </div>
              </div>
              {load && <FavSesnorenWidget sensoren={sensoren} />}
              <Loading loadings={load} />
            </div>
          </div>
        )}
        {vaild(["admin", "manager", "medewerker", "faciliteitmedewerker"]) && ismodule("accommodaties") && (
          <div className="col-md-12">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Accommodaties</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("accommodaties")}>
                    X
                  </button>
                </div>
              </div>
              {load && <FavAccoWidget sensoren={sensoren} />}
              <Loading loadings={load} />
            </div>
          </div>
        )}

        {vaild(["admin", "manager", "medewerker", "faciliteitmedewerker"]) && ismodule("sensoren") && (
          <div className="col-md-12">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Lijst sensoren</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("sensoren")}>
                    X
                  </button>
                </div>
              </div>
              {load && <SensorWidget sensoren={sensoren} />}
              <Loading loadings={load} />
            </div>
          </div>
        )}
        {vaild(["admin", "manager", "hoofdrecreatie", "faciliteitmedewerker"]) && ismodule("activiteiten") && (
          <div className="col-xl-6">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Activiteiten waar je voor in kan schrijven</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("activiteiten")}>
                    X
                  </button>
                </div>
              </div>
              <div className="box-body">
                <ActiviteitenWidget />
              </div>
            </div>
          </div>
        )}
        {vaild(["admin", "manager", "hoofdrecreatie", "faciliteitmedewerker"]) && ismodule("faciliteiten") && (
          <div className="col-xl-6">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Lijst met Faciliteiten</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("faciliteiten")}>
                    X
                  </button>
                </div>
              </div>
              <div className="box-body">
                <FaciliteitenWidget />
              </div>
            </div>
          </div>
        )}
        {vaild(["admin", "manager", "medewerker", "faciliteitmedewerker"]) && ismodule("items") && (
          <div className="col-xl-6">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">VVV Items</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("items")}>
                    X
                  </button>
                </div>
              </div>
              <div className="box-body">
                <VVVItemsWidget history={history} />
              </div>
            </div>
          </div>
        )}
        {vaild(["admin", "manager", "medewerker", "ervaringeigenaar"]) && ismodule("ervaringen") && (
          <div className="col-xl-6">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Aankomende ervaringen</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("ervaringen")}>
                    X
                  </button>
                </div>
              </div>
              <div className="box-body">
                <ErvaringenWidgetAankomende />
              </div>
            </div>
          </div>
        )}
        {vaild(["admin", "manager", "medewerker", "ervaringeigenaar"]) && ismodule("ervaringen") && (
          <div className="col-md-12">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Overzicht nieuwe boekingen</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("ervaringen")}>
                    X
                  </button>
                </div>
              </div>
              <div className="box-body">
                <BoekingenWidget />
              </div>
            </div>
          </div>
        )}
        {vaild(["admin", "manager", "medewerker", "ervaringeigenaar"]) && ismodule("ervaringen") && (
          <div className="col-md-12">
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Ervaringen</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-sm" onClick={() => toggleVisibility("ervaringen")}>
                    X
                  </button>
                </div>
              </div>
              <div className="box-body">
                <ErvaringenWidget />
              </div>
            </div>
          </div>
        )}

        {ismodule("activiteiten") && (
          <>
            <div className="col-xl-6">
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Vragen of opmerkingen</h3>
                  <div className="box-tools pull-right">
                    <button className="btn btn-sm" onClick={() => toggleVisibility("activiteiten")}>
                      X
                    </button>
                  </div>
                </div>
                <div className="box-body">
                  Heb je bij het gerbuik een vraag of het gebruik van recraplan. Stuur dan een mail naar <a href="mailto:support@recraplan.nl">support@recraplan.nl</a>
                  <p>We zullen je vraag zo snel mogelijk beantwoorden</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Handleidingen</h3>
                </div>
                <div className="box-body clearfix">
                  <h5>Download de handleidingen</h5>
                  <a target="_blank" rel="noopener noreferrer" download href="http://download.recraplan.nl/Algemeen - Recraplan Inschrijven Activiteiten NL FR DE.pdf" type="button" className="btn btn-default btn-block btn-flat">
                    Instructie Recraplan – activiteiten inschrijven
                  </a>
                  <a target="_blank" rel="noopener noreferrer" download href="http://download.recraplan.nl/Algemeen - Recraplan instructie voor gasten NL FR DE.pdf" type="button" className="btn btn-default btn-block btn-flat">
                    Instructie Recraplan – Instructie gasten
                  </a>
                  <a target="_blank" rel="noopener noreferrer" download href="http://download.recraplan.nl/Algemeen - Recraplan Reserveren Faciliteiten NL FR DE.pdf" type="button" className="btn btn-default btn-block btn-flat">
                    Instructie Recraplan – Reserveren faciliteiten
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { user: state.auth.user, sensoren: state.sensoren.items };
}

export default connect(mapStateToProps, { fetchsensoren })(Startpage);
