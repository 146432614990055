import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { insertkampeerplaatsen, fetshsoortsensoren, fetchstanplaatscategorien, fetchrelais, fetchveldkasten } from "../Reducers/actions";
import BedrijvenForm from "./Components/form";
import { useNavigate } from "react-router-dom";

const KampeerplaatsInsert = ({ soortsensoren, categorien, insertkampeerplaatsen, fetshsoortsensoren, fetchstanplaatscategorien, fetchrelais, relais, fetchveldkasten , veldkasten}) => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = (vals) => {
    insertkampeerplaatsen(vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        navigate("/standplaatsen/kampeerplaatsen");
      }
    });
  };

  useEffect(() => {
    fetshsoortsensoren({ soort: "electra" }).then(() => {
      fetchrelais().then(() => {
        fetchstanplaatscategorien().then(() => {
          fetchveldkasten().then(() => {
            setLoaded(true);
          });

        });
      });
    });
  }, [dispatch]);

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Kampeerplaats toevoegen</h3>
      </div>

      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile">{loaded && <BedrijvenForm categorien={categorien} veldkasten={veldkasten} sensoren={soortsensoren} onSubmit={submit} relais={relais} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, soortsensoren: state.standplaatsen.sensoren, categorien: state.standplaatsen.categorien, relais: state.standplaatsen.relaiss, veldkasten: state.standplaatsen.veldkasten,  };
}

export default connect(mapStateToProps, { insertkampeerplaatsen, fetshsoortsensoren, fetchstanplaatscategorien, fetchrelais, fetchveldkasten })(KampeerplaatsInsert);
