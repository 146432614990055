import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./style.scss";
const GastMenu = ({ token, username }) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && username.bedrijf.modules.includes("gastenverbruik")) {
    if (token.role === "admin" || token.role === "manager" || token.role === "medewerker") {
      return (
        <li className="treeview">
          <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
            <i className="fas fa-user-check"></i> <span>Verbruiksgegevens</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "}>
            <li>
              <Link to="/gastenverbruik/" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book-open" />
                <span>Reserveringen</span>
              </Link>
            </li>
            <li>
              <Link to="/gastenverbruik/gasten" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-users" />
                <span>Gasten</span>
              </Link>
            </li>
            <li>
              <Link to="/gastenverbruik/insert" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-user-plus" />
                <span>Reservering Toevoegen</span>
              </Link>
            </li>
            <li>
              <Link to="/gastenverbruik/gastenprofiel" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-users-gear" />
                <span>Gastprofielen</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/gastenverbruik/duurzaam" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-user-plus" />
                <span>Duurzaamheid Tips</span>
              </Link>
            </li> */}
          </ul>
        </li>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export default GastMenu;
