import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/nl";
import "react-datepicker/dist/react-datepicker.css";

const Datetime = ({ input, label, error, warning, touched, maxDate, minDate, minTime, timeIntervals, defaultValue }) => {
  const [date, setDate] = useState("");
  const [maxDateState, setMaxDate] = useState(false);
  const [minDateState, setMinDate] = useState(false);
  const [minTimeState, setMinTime] = useState(false);

  useEffect(() => {
    if (input.value) {
      const newDate = moment(input.value).toDate();
      setDate(newDate);
    }
  }, [input.value]);

  useEffect(() => {
    if (maxDate) {
      setMaxDate(new Date(maxDate));
    }
    if (minDate) {
      setMinDate(true);
    }
    if (minTime) {
      setMinTime(true);
    }
  }, [maxDate, minDate, minTime]);

  const handleSelect = (val) => {
    const value = moment(val).toDate();
    setDate(val);
    input.onChange(value);
  };

  // Update minTime based on the selected date
  const getMinTime = () => {
    if (minTimeState) {
      const today = moment().startOf("day");
      const selectedDay = moment(date).startOf("day");
      return today.isSame(selectedDay) ? moment().toDate() : moment().startOf("day").toDate();
    } else {
      return false;
    }
  };

  const getMaxTime = () => {
    if (minTimeState) {
      return moment().endOf("day").toDate();
    } else {
      return false;
    }
  };

  const getMaxDate = () => {
    if (minDateState) {
      return new Date();
    } else {
      return false;
    }
  };

  return (
    <div>
      <label>{label}</label>
      <div>
        <DatePicker
          selected={date}
          onChange={handleSelect}
          showTimeSelect
          className="form-control form-control-solid w-250px"
          minDate={getMaxDate()}
          timeFormat="HH:mm"
          timeIntervals={timeIntervals ? timeIntervals : "15"} // Je kunt dit aanpassen aan je behoefte
          timeCaption="Tijd"
          maxDate={maxDateState}
          minTime={getMinTime()} // Minimale tijd update
          maxTime={getMaxTime()} // Max tijd is einde van de dag
          dateFormat="dd-MM-yyyy HH:mm"
        />
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default Datetime;
