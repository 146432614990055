import React from "react";
import { Routes, Route } from "react-router-dom";
import IndexKampeerplaatsen from "./Kampeerplaatsen/index";
import KampeerplaatsInsert from "./Kampeerplaatsen/insert";
import KampeerplaatsEditPage from "./Kampeerplaatsen/edit";
import Kampeerplaats from "./Kampeerplaatsen/kampeerplaats";

import IndexAccommodaties from "./Accommodaties/index";
import AccommodatieInsert from "./Accommodaties/insert";
import AccommodatieEditPage from "./Accommodaties/edit";
import Accommodatie from "./Accommodaties/accommodatie";
import AccommodatieShow from "./Accommodaties/show";
import IndexCategorien from "./Categorien/index";
import InsertCategorie from "./Categorien/insert";
import EditCategire from "./Categorien/edit";
import KampeerplaatsWijzigenPage from "./Kampeerplaatsen/wijzigen";
import VeldkastPage from "./Veldkasten";
import InsertVeldgast from "./Veldkasten/insert";
import EditVeldkastPage from "./Veldkasten/edit";

const StandplaatsRouter = () => {
  return (
    <Routes>
      <Route path="/kampeerplaatsen/" element={<IndexKampeerplaatsen />} />
      <Route path="/kampeerplaatsen/insert" element={<KampeerplaatsInsert />} />
      <Route path="/kampeerplaatsen/edit/:id" element={<KampeerplaatsEditPage />} />
      <Route path="/kampeerplaatsen/wijzigen/:id" element={<KampeerplaatsWijzigenPage />} />
      <Route path="/kampeerplaatsen/show/:id" element={<Kampeerplaats />} />
      <Route path="/accommodaties/" element={<IndexAccommodaties />} />
      <Route path="/accommodaties/insert" element={<AccommodatieInsert />} />
      <Route path="/accommodaties/edit/:id" element={<AccommodatieEditPage />} />
      <Route path="/accommodaties/show/:id" element={<AccommodatieShow />} />
      <Route path="/accommodaties/show/:id/:sensor_id" element={<Accommodatie />} />
      <Route path="/categorien/" element={<IndexCategorien />} />
      <Route path="/categorien/insert" element={<InsertCategorie />} />
      <Route path="/categorien/edit/:id" element={<EditCategire />} />
      <Route path="/veldkasten/" element={<VeldkastPage />} />
      <Route path="/veldkasten/insert" element={<InsertVeldgast />} />
      <Route path="/veldkasten/edit/:id" element={<EditVeldkastPage />} />
    </Routes>
  );
};

export default StandplaatsRouter;
