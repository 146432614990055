import { Link } from "react-router-dom";
import "./style.scss";
import React, { useState } from "react";
const StansplaatsSidebar = ({ token, username }) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && ["kampeerplaatsen", "accommodaties"].some((module) => username.bedrijf.modules.includes(module))) {
    if (token.role === "admin" || token.role === "manager" || token.role === "medewerker") {
      function addkampeerplaats() {
        if (token.role === "admin" || token.role === "manager") {
          return (
            <>
              <hr className="m-2 text-white" />
              {username.bedrijf && username.bedrijf.modules.includes("kampeerplaatsen") && (
                <li>
                  <Link to="/standplaatsen/kampeerplaatsen/insert" onClick={() => setIsActive(!isActive)}>
                    <i className="fa fa-tent" />
                    <span>Verblijfsplaats toevoegen</span>
                  </Link>
                </li>
              )}
              {username.bedrijf && username.bedrijf.modules.includes("accommodaties") && (
                <li>
                  <Link to="/standplaatsen/accommodaties/insert" onClick={() => setIsActive(!isActive)}>
                    <i className="fa fa-house" />
                    <span>Accommodatie toevoegen</span>
                  </Link>
                </li>
              )}
              <li>
                <Link to="/standplaatsen/categorien" onClick={() => setIsActive(!isActive)}>
                  <i className="fa fa-book" />
                  <span>Categorien</span>
                </Link>
              </li>
              <li>
                <Link to="/standplaatsen/veldkasten" onClick={() => setIsActive(!isActive)}>
                  <i className="fa fa-book" />
                  <span>Velkasten</span>
                </Link>
              </li>
            </>
          );
        }
      }

      return (
        <li className="treeview">
          <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
            <i className="fa-solid fa-sailboat"></i> <span>Ligplaatsen’</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={`treeview-menu ${isActive ? "menu-open" : ""}`}>
            {username?.bedrijf?.modules?.includes("kampeerplaatsen") && (
              <li>
                <Link to="/standplaatsen/kampeerplaatsen/" onClick={() => setIsActive(!isActive)}>
                  {username.bedrijf.soort === "jachthaven" ? (
                    <>
                      <i className="fas fa-sailboat"></i>
                      <span>Ligplaatsen</span>
                    </>
                  ) : (
                    <>
                      <i className="fas fa-caravan"></i>
                      <span>Kampeerplaatsen</span>
                    </>
                  )}
                </Link>
              </li>
            )}

            {username?.bedrijf?.modules?.includes("accommodaties") && (
              <li>
                <Link to="/standplaatsen/accommodaties/" onClick={() => setIsActive(!isActive)}>
                  <i className="fas fa-house"></i>
                  <span>Accommodaties</span>
                </Link>
              </li>
            )}

            {addkampeerplaats()}
          </ul>
        </li>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export default StansplaatsSidebar;
