import React, { useEffect, useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import { required } from "redux-form-validators";
import { connect, useDispatch, useSelector } from "react-redux";
import { renderField, renderselapi, renderselect } from "../../../Components/Forms/renders";
import { fechgastenprofiel } from "../Reducers/actions";

const InsertGastForm = ({ handleSubmit, emailadres, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const reduxDispatch = useDispatch();

  // Stel het e-mailadres in bij het laden van het formulier
  useEffect(() => {
    dispatch(change("insertgastenGasten", "email", emailadres));
  }, [dispatch, emailadres]);

  // Ophalen van gastenprofielen
  const gastenprofielen = useSelector((state) => state.gastenverbruik.gastenprofielen || []);

  useEffect(() => {
    reduxDispatch(fechgastenprofiel())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [reduxDispatch]);

  return (
    <div>
      {!loading ? (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <Field name="email" type="email" component={renderField} label="Email" validate={required({ msg: "Dit item is verplicht" })} />
              <Field name="gastenprofiel" type="input" label="Gasten Profiel" options={gastenprofielen} inputnaam="naam" inputvalue="_id" component={renderselapi} validate={required({ msg: "Dit item is verplicht" })} />
              <Field name="voornaam" type="text" label="Voornaam" component={renderField} />
              <Field name="tussennaam" type="text" label="Tussennaam" component={renderField} />
              <Field name="achternaam" type="text" label="Achternaam" component={renderField} />
              <Field name="soort" type="text" label="Soort" component={renderselect} />
            </div>
          </div>
        </div>
        <div className="pull-left submit">
          <button type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg">
            Opslaan
          </button>
        </div>
      </form>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default connect()(
  reduxForm({
    form: "insertgastenGasten",
  })(InsertGastForm)
);
