import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Upload } from "../../Pages/Items/Reducers/items/actions";

const UploadForm = ({ label, Upload, onClose, input, isModal, minSize }) => {
  const [disabled, setDisabled] = useState(true);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  useEffect(() => {
    if (input.value && input.value.length > 0) {
      const updatedFiles = input.value.map((file) => {
        if (file && file.thumbnail && file.thumbnail.small) {
          return {
            ...file, // Kopieer alle eigenschappen van het bestaande bestand
            preview: file.thumbnail.small, // Voeg de preview eigenschap toe
          };
        }
        return file;
      });

      setFiles(updatedFiles); // Update de state met de nieuwe array
    }
  }, [input.value]);

  const onDrop = (newFiles, rejectedFiles) => {
    let errors = {};
    setNewFiles(
      newFiles.map((f) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      )
    );
    setErrors(errors);
  };

  const handleUpload = async () => {
    try {
      const result = files;
      for (let index = 0; index < newFiles.length; index++) {
        const file = newFiles[index];
        const data = await Upload({ file });

        if (data.type === "ITEM_ERROR") {
          const newErrors = { message: data.payload };
          setErrors(newErrors);
          return true;
        } else {
          result.push(data);
        }
      }
      input.onChange(result);
      setNewFiles([]);
      setSuccess({ message: "Uploaden is gelukt" });
      if (isModal) onClose();
    } catch (e) {
      console.log(e);
    }
  };
  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };
  return (
    <div>
      <label>{label}</label>
      <div>
        <Dropzone onDrop={onDrop} minSize={minSize? minSize : 0} >
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                minHeight: "100px",
                borderWidth: "2px",
                borderColor: "rgb(102, 102, 102)",
                borderStyle: "dashed",
                borderRadius: "5px",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                style={{
                  minHeight: "100px",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p>Kies je bestand of sleep hem hier naar toe</p>
              </div>
            </section>
          )}
        </Dropzone>
        <ul className="users-list clearfix">
          {newFiles.map((f, index) => (
            <li key={index}>
              <button type="button" className="close" onClick={() => handleDelete(index)} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <img src={f.preview} alt="Afbeelding" />
              <span className="users-list-date">{f.name}</span>
            </li>
          ))}
        </ul>
        {!isModal && (
          <ul className="users-list clearfix">
            {files.map((f, index) => (
              <li key={index}>
                <button type="button" className="close" onClick={() => handleDelete(index)} aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                <img src={f.preview} alt="Afbeelding" />
                <span className="users-list-date">{f.name}</span>
              </li>
            ))}
          </ul>
        )}
        {errors && <div className="text-danger">{errors.message}</div>}
        {success && <div className="text-success">{success.message}</div>}
        <input type="button" onClick={handleUpload} className="btn btn-next btn-fill btn-success btn-wd btn-sm" value="Uploaden" />
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { Upload })(UploadForm);
