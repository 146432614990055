import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import renderDaterange from "../../../../Components/Forms/daterange";
import { renderselectonlyname } from "../../../../Components/Forms/renders";

const HeaderFinancieel = ({ changedata }) => {
  const onchangedatum = (vals) => {
    setTimeout(() => {
      changedata({ datum: vals });
    }, 200);
  };

  // Verwerk arrangementen en ervaringen samen

  return (
    <form>
      <div className="row container filter ">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <Field name="datum" type="number" mindate={true} maxdate={false} component={renderDaterange} label="Kies een datum" onChange={onchangedatum} />
        </div>
      </div>
      <hr />
      <div className="row container filter my-2 "></div>
    </form>
  );
};

const reduxConnectedForm = reduxForm({ form: "financieelheader" })(HeaderFinancieel);

const mapStateToProps = (state) => ({
  initialValues: {
    datum: { startdate: moment().startOf('year').toDate(), enddate: new Date() },
  },
});

export default connect(mapStateToProps)(reduxConnectedForm);
