import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { message } from "../../../Reducers/loading/actions";

import { Sendmail, Login, authError } from "../../../Reducers/auth/actions";

const LoginPage = ({ errorMessage, config, Login, Sendmail, authError, message }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    sendmail: false,
    sendmailluck: false,
  });
  const [attemptLogin, setAttemptLogin] = useState(false);

  const userLogin = async (e) => {
    e.preventDefault();
    await Login({
      email: credentials.email,
      password: credentials.password,
      strategy: "local",
    }).then((whatever) => {
      console.log(whatever);  
      if (whatever) {
        if (!whatever.check) {
          localStorage.setItem("token", whatever.token);
          message(1000, "success", "Het inloggen is gelukt");
          setTimeout(function () {
            window.location.replace("/");
          }, 500); // How long do you want the delay to be (in milliseconds)?
        }
        if (whatever.check === "blocked") {
          message(3000, "danger", "Je account je geblokkeerd");
        }
        if (whatever.check === "sms") {
          navigate("/login/sms/" + whatever.token);
        }
        if (whatever.check === "email") {
          setCredentials({ ...credentials, sendmail: true });
        }
      }
    });
  };
  const sendvmail = async () => {
    const whatever = await Sendmail({ email: credentials.email });
    if (whatever) {
      setCredentials({ ...credentials, sendmail: false, sendmailluck: true });
    }
  };

  const onChange = (event) => {
    authError(null);
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (!code) {
      authError(null);
      return;
    }

    try {
      const bytes = CryptoJS.AES.decrypt(code, "882eh2h9e8hhe01ihe81tye01ihe98ye0");
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData.email && decryptedData.password) {
        setCredentials({
          ...credentials,
          email: decryptedData.email,
          password: decryptedData.password,
        });
      }
      setAttemptLogin(true); // Trigger poging om in te loggen
    } catch (err) {
      console.log("Fout bij decryptie of JSON-parsing:");
    }
  }, [location]); // Voeg location toe aan dependencies array

  useEffect(() => {
    // Probeer in te loggen als credentials zijn gezet
    if (attemptLogin) {
      userLogin({ preventDefault: () => {} });
      setAttemptLogin(false); // Reset de login trigger
    }
  }, [attemptLogin, credentials]); // Reageer op veranderingen in attemptLogin en credentials

  useEffect(() => {
    if (errorMessage) {
      message(5000, "danger", errorMessage);
    }
    if (credentials.sendmailluck) {
      message(5000, "info", "Er is een mail gestuurd om je account te activeren");
    }
  }, [errorMessage, credentials.sendmailluck]);

  const renderAlert = () => {
    if (credentials.sendmail) {
      return (
        <div className="alert alert-danger">
          Je e-mailadres is niet gecontroleerd. Klik{" "}
          <strong className="pointer" onClick={sendvmail}>
            hier
          </strong>{" "}
          om nogmaals je account te activeren
          <br />
        </div>
      );
    }
  };

  return (
    <div className="login-box-body">
      <p className="login-box-msg">Vul je gegevens in om in te loggen</p>
      {renderAlert()}
      <form method="post">
        <div className="form-group has-feedback">
          <input value={credentials.email} onChange={onChange} type="email" name="email" className="form-control" placeholder="E-mail adres" />
        </div>
        <div className="form-group has-feedback">
          <input value={credentials.password} onChange={onChange} type="password" name="password" className="form-control" placeholder="Wachtwoord" />
        </div>
        <div className=" form-group has-feedback row">
          <div className="col-xs-8"></div>
          {/* /.col */}
          <div className="col-xs-4">
            <button type="submit" onClick={(e) => userLogin(e)} className="loginbutton btn btn-primary btn-block btn-flat">
              Inloggen
            </button>
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-8">
          <Link to="/login/forget">Wachtwoord vergeten</Link>
        </div>
        {config.openregist && (
          <div className="col-4">
            <Link to="/login/forget">Maak Account</Link>
          </div>
        )}
      </div>
      <br />
    </div>
  );
};

const mapStateToProps = (state) => ({
  errorMessage: state.auth.error,
  config: state.auth.config,
});

const mapDispatchToProps = {
  Sendmail,
  Login,
  authError,
  message,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
