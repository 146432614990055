import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchonegasten, fechgastenprofiel, updategasten, deletegast } from "../Reducers/actions";
import { useParams, useNavigate } from "react-router-dom";
import EditGastForm from "./Components/edit";

const KampeerplaatsEditPage = ({ fetchonegasten, updategasten, fechgastenprofiel, deletegast }) => {
  const [loaded, setLoaded] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const submitgast = (vals) => {
    updategasten(params.id, vals).then((data) => {
      if (data.type === "GASTEN_VERBRUIK_ERROR") {
        alert("Er us een fout opgetreden");
        return false;
      } else {
        navigate("/gastenverbruik/gasten");
      }
    });
  };

  const deletegastitem = () => {
    const confirmed = window.confirm("Weet je zeker dat je deze gast wilt verwijderen? Alle reserveringen wordt dan ook verwijderd");
    if (!confirmed) return;

    deletegast(params.id).then((data) => {
      if (data.type === "GASTEN_VERBRUIK_ERROR") {
        alert("Er is een fout opgetreden");
        return false;
      } else {
        navigate("/gastenverbruik/gasten");
      }
    });
  };

  useEffect(() => {
    fetchonegasten(params.id).then((data) => {
      fechgastenprofiel().then(() => {
        setLoaded(true);
      });
    });
  }, [loaded]);
  if (!loaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Gast wijzigen</h3>
          <div className="box-tools pull-right">
            <button type="button" className="btn-warning btn btn-box-too btn-sm mx-2" onClick={() => deletegastitem()}>
              <i className="fa fa-trash"></i>&nbsp; Gast verwijderen
            </button>
          </div>
        </div>
        <div className="box box-default">
          <div className="box-body">
            <EditGastForm onSubmit={submitgast} />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  gast: state.gastenverbruik.gast,
});

export default connect(mapStateToProps, { updategasten, fetchonegasten, fechgastenprofiel, deletegast })(KampeerplaatsEditPage);
