import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchkampeerplaatsen } from "../../Reducers/actions";
import moment from "moment";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import { getsensorstatus } from "../../../Sensors/Reducers/actions";
import { addgastengebruikmeter, finishedboeking } from "../../../GastenVerbruik/Reducers/actions";
import Instellenmeter from "./instellenmeter";

function FavSesnorenWidget({ kampeerplaatsen, fetchkampeerplaatsen, getsensorstatus, addgastengebruikmeter, finishedboeking }) {
  const [load, setLoad] = useState(true);
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    // Eerste keer laden
    fetchkampeerplaatsen().then(() => setLoad(false));

    // Interval om elke 30 seconden opnieuw op te halen
    const interval = setInterval(() => {
      fetchkampeerplaatsen();
    }, 30000); // 30.000 ms = 30 seconden

    // Opruimen bij unmount
    return () => clearInterval(interval);
  }, []);
  function isalert(sensor) {
    if (sensor.active == false) return "card-header switchoff";
    if (sensor.status == "false") return "card-header alerttemp";
    else return "card-header";
  }
  function luodate(momn) {
    if (momn.data && momn.data[0]) return moment(momn.data[0].datum).fromNow();
  }
  function graph(row) {
    const data = [];
    if (row.data.length > 0) {
      row.data.map(function (a, key) {
        if (key > 0) {
          data.push(Number(row.data[key - 1].value - Number(a.value)));
        }
        return a;
      });
    }
    return (
      <Sparklines height={80} data={data.reverse()}>
        <SparklinesLine color="#848c45" limit={20} />
        <SparklinesSpots />
      </Sparklines>
    );
  }
  function lastvalue(row) {
    if (row.data.length > 0) {
      return Number(row.data[0].value).toFixed(2);
    } else {
      return null;
    }
  }

  const handleSubmit = (vals, dispatch, props) => {
    setShowModal({ text: "We zijn de meter aan het instellen" });

    addgastengebruikmeter(vals).then((data) => {
      if (data.type === "ADD_GASTEN_VERBRUIK") {
        fetchkampeerplaatsen().then(() => {
          setShowModal(false);
          props.reset(); // Reset het formulier
        });
      } else {
        alert("Er is een fout opgetreden bij het instellen van de meter");
      }
    });
  };

  const boekingafronden = (id, vals) => {
    setShowModal({ text: "We zijn de boeking aan het afronden" });
    finishedboeking(id, vals).then((data) => {
      if (data.type === "ADD_GASTEN_VERBRUIK") {
        fetchkampeerplaatsen().then((data) => {
          setShowModal(false);
        });
      } else {
        alert("Er is een fout opgetreden bij het afronden van de boeking");
      }
    });
  };

  const reloadplaatsen = () => {
    fetchkampeerplaatsen().then(() => {
      setLoad(false);
    });
  };
  return (
    <div className="row">
      {load ? (
        <div className="col-lg-12">
          <div className="alert alert-info">Laden...</div>
        </div>
      ) : kampeerplaatsen.length > 0 ? (
        kampeerplaatsen.map((sensor, key) => (
          <div key={key} className="col-lg-4 p-4">
            <div className="tempsensor card card-primary">
              <div className={isalert(sensor)}>
                <h3 className="card-title">{sensor.naam}</h3>
              </div>
              {graph(sensor)}
              <hr />{" "}
              <div className="details d-flex">
                {/* Relais status */}

                {sensor?.sensor?.electra?.status === "processing" ? (
                  <div className="text-center mx-3">
                    <div className="spinner-border" role="status" style={{ width: "40px", height: "40px" }}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="text-center mx-3">
                    <img src={sensor?.sensor?.electra?.ingeschakeld ? "/img/plug_ok.png" : "/img/plug_error.png"} width={40} height={40} alt="Relais status" />
                    <div className="small-text">{sensor?.sensor?.electra?.ingeschakeld ? "Ingeschakeld" : "Uitgeschakeld"}</div>
                  </div>
                )}
                {/* Boeking status */}
                {sensor?.sensor?.boeking?.gastinfo && (
                  <div className="text-center mx-3">
                    <img src="/img/booking.png" width={40} height={40} alt="Boeking aanwezig" />
                    <div className="small-text">Boeking</div>
                  </div>
                )}

                {/* Tegoed van de boeking */}
                {sensor?.sensor?.boeking?.tegoed && (
                  <div className="text-center mx-3">
                    <img src="/img/kwh.png" width={40} height={40} alt="Tegoed" />
                    <div className="small-text">{sensor?.sensor?.boeking?.tegoed} kWh</div>
                  </div>
                )}

                {sensor?.sensor?.boeking?.gastinfo && (
                  <div className="text-center mx-3">
                    {sensor?.sensor?.boeking?.gastinfo?.achternaam ? (
                      <>
                        <img src="/img/user.png" width={40} height={40} alt="Boeker" />
                        <div className="small-text">{`${sensor?.sensor?.boeking?.gastinfo?.tussennaam || ""} ${sensor?.sensor?.boeking?.gastinfo?.achternaam}`}</div>
                      </>
                    ) : (
                      <>
                        <img src="/img/boot.png" width={40} height={40} alt="Boot" />
                        <div className="small-text">{sensor?.sensor?.boeking?.gastinfo?.bootnaam || "Bootnaam onbekend"}</div>
                      </>
                    )}
                  </div>
                )}
                {sensor?.sensor.kwh > 0 && (
                  <div className="text-center mx-3">
                    <span className="kwhtegoed">{Number(sensor?.sensor.uitschakelwaarde - sensor?.sensor?.lastvalue).toFixed(2)}</span> kWh
                    <div className="small-text">Tegoed</div>
                  </div>
                )}
                {sensor.sensor.boeking && !sensor?.sensor?.boeking?.betaald && (
                  <div className="text-center mx-3">
                    <img src="/img/erorpaid.png" width={50} height={40} alt="Boeker" />
                    <div className="small-text">Niet betaald</div>
                  </div>
                )}
              </div>
              <hr />
              <div className="details">
                {sensor.active ? <div className="valuekwh">{lastvalue(sensor)}</div> : <div className="valueswitchoff">Uitgeschakeld</div>}

                <div className="meeting">Laatste meting {luodate(sensor)}</div>
              </div>
              <div className="card-footer">
                <Instellenmeter boekingafronden={boekingafronden} showModal={showModal} sensor={sensor} onSubmit={handleSubmit} reloadplaatsen={reloadplaatsen} plaatsen={kampeerplaatsen} />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="col-lg-12">
          <div className="alert alert-info">Er zijn geen sensoren gevonden.</div>
        </div>
      )}
    </div>
  );
}
function mapStateToProps(state) {
  return { user: state.auth.user, kampeerplaatsen: state.standplaatsen.kampeerplaatsen };
}
export default connect(mapStateToProps, { fetchkampeerplaatsen, getsensorstatus, addgastengebruikmeter, finishedboeking })(FavSesnorenWidget);
