import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import IndexGastenverbuik from "./index";
import InsertGastenVerbruik from "./insert";
import EditGastenverbruik from "./edit";
import ShowGastenverbruik from "./kampeerplaats";
import ShowGastenverbruikAcco from "./accommodatie";
import DuurzaamTipsOverzicht from "./Duurzaamtips/index";
import InsertDuurzaamTip from "./Duurzaamtips/insert";
import DuurzameTipShow from "./Duurzaamtips/show";
import DuurzaamTipsUpdate from "./Duurzaamtips/edit";
import GastenprofielPage from "./Gastenprofiel/index";
import GastenProfielEdit from "./Gastenprofiel/edit";
import GastenProfielnsertPage from "./Gastenprofiel/insert";
import Gasten from "./Gasten/index";
import EditGastForm from "./Gasten//edit"
import InsertGasten from "./Gasten/insert";
import ShowGasten from "./Gasten/show";
import GastenUpload from "./Gasten/upload";

const GastenverbruikRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<IndexGastenverbuik />} />
      <Route exact path="/insert" element={<InsertGastenVerbruik />} />
      <Route exact path="/gasten" element={<Gasten />} />

      <Route exact path="/gasten/:id" element={<ShowGasten />} />
      <Route exact path="/gasten/insert/" element={<InsertGasten />} />
      <Route exact path="/gasten/upload/" element={<GastenUpload />} />
      <Route exact path="/gasten/edit/:id" element={<EditGastForm />} />
      <Route exact path="/kampeerplaats/:id" element={<ShowGastenverbruik />} />
      <Route exact path="/accommodatie/:id" element={<ShowGastenverbruikAcco />} />
      <Route exact path="/accommodatie/elektra/:id" element={<ShowGastenverbruik />} />
      <Route exact path="/edit/:id" element={<EditGastenverbruik />} />
      <Route exact path="/duurzaam" element={<DuurzaamTipsOverzicht />} />
      <Route exact path="/duurzaam/insert" element={<InsertDuurzaamTip />} />
      <Route exact path="/duurzaam/show/:id" element={<DuurzameTipShow />} />
      <Route exact path="/duurzaam/edit/:id" element={<DuurzaamTipsUpdate />} />
      <Route exact path="/gastenprofiel" element={<GastenprofielPage />} />
      <Route exact path="/gastenprofiel/insert" element={<GastenProfielnsertPage />} />
      <Route exact path="/gastenprofiel/edit/:id" element={<GastenProfielEdit />} />
    </Routes>
  );
};

export default GastenverbruikRouter;
