import React, { useState, useEffect, use } from "react";
import { Field, reduxForm, initialize } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Rodal from "rodal";
import { required, numericality } from "redux-form-validators";
import { renderField } from "../../../../Components/Forms/renders";
import { getsensordevice_id, getsensorstatus, schakelkwhnmeter } from "../../../Sensors/Reducers/actions";
import AutosugformApi from "../../../../Components/Forms/autocompleteapi";
import DateTimeInput from "../../../../Components/Forms/datetime";
import AddKWHForm from "./forms/addkwh";
import VerplaatsenBoeking from "./forms/verplaatsen";
import Newgast from "../../../GastenVerbruik/Gasten/Components/insert";
import ChangeVertrek from "./forms/vertrek";
import { addwkhboeking, verplaatsboeking, changedatedepart, addgasten } from "../../.././GastenVerbruik/Reducers/actions";

const InstellenMeter = ({ handleSubmit, getsensordevice_id, getsensorstatus, sensor, dispatch, showModal, boekingafronden, addgasten, addwkhboeking, reloadplaatsen, plaatsen, verplaatsboeking, schakelkwhnmeter, changedatedepart }) => {
  const [visible, setVisible] = useState(false);
  const [loadstatus, setLoadstatus] = useState(false);
  const [soortgast, setSoortgast] = useState(false);
  const [progress, setProgress] = useState(0);
  const [newgast, setNewgast] = useState(false);
  const [error, setError] = useState(false);
  const [addnewkwh, setAddnewkwh] = useState(false);
  const [vertrekwijzigen, setVertrekwijzigen] = useState(false);
  const [boekingverplaatsen, setBoekingverplaatsen] = useState(false);
  useEffect(() => {
    if (visible) {
      getsensordevice_id(sensor.device_id).then((data) => {
        // getstatus(data.results.device_id);
      });
    }
  }, [visible]);

  useEffect(() => {
    if (soortgast) {
      getsensordevice_id(sensor.device_id).then((data) => {
        const initialData = {
          aankomst: moment().toDate(),
          device_id: data.results.device_id,
          standplaats: sensor._id,
          device_id: sensor.device_id,
          gast: soortgast,
          sensor_id: sensor.sensor._id,
          kwh: soortgast?.gastenprofiel?.minimaalkwh || 0,
          vertrek: soortgast?.gastenprofiel?.minaantaldagen
            ? moment()
                .add(soortgast?.gastenprofiel?.minaantaldagen, "days")
                .set({
                  hour: +soortgast?.gastenprofiel?.uitchecktijd?.split(":")[0] || 12,
                  minute: +soortgast?.gastenprofiel?.uitchecktijd?.split(":")[1] || 0,
                  second: 0,
                  millisecond: 0,
                })
                .toDate()
            : "",
        };

        dispatch(initialize("setupmeterinsert", initialData));
      });
    }
  }, [soortgast]);
  useEffect(() => {
    if (!showModal && visible) {
      setVisible(false);
      setProgress(0);
    }
    if (showModal.text && visible) {
      setLoadstatus(showModal.text);
      setProgress(0);
    }
  }, [showModal]);
  useEffect(() => {
    if (loadstatus) {
      let timeElapsed = 0;
      const interval = setInterval(() => {
        timeElapsed += 100;
        setProgress((prev) => Math.min(prev + 1, 100)); // Max 100%
        if (timeElapsed >= 10000) {
          clearInterval(interval);
          setLoadstatus(null);
          setProgress(0);
        }
      }, 100); // Elke 100ms +1%
      return () => clearInterval(interval);
    }
  }, [loadstatus]);

  const getstatus = (meterId) => {
    setLoadstatus("We halen de status op van de meter"); // Zorg ervoor dat de status niet blijft hangen

    getsensorstatus(meterId, { payload: "AQMAgAABheI=", field: "status" })
      .then((data) => {
        if (data.type === "SENSOR_ERROR") {
          setError("Er is een fout opgetreden bij het ophalen van de status");
          return;
        }
        reloadplaatsen();
        setLoadstatus(false);
        setError(false);
        setProgress(0);
      })

      .catch((error) => {
        console.error("Fout bij het ophalen van de status:", error);
        alert("Er is een fout opgetreden bij het ophalen van de status.");
        setLoadstatus(false);
        setProgress(0);
      });
  };

  const submitgast = (vals) => {
    addgasten(vals).then((data) => {
      if (data.type === "SET_GAST") {
        setNewgast(false);
        setError(false);
      } else {
        setError(data.error.data.global);
      }
    });
  };

  const submitvetrek = (vals) => {
    setLoadstatus("De vetrekdatum wordt gewijzigd"); // Zorg ervoor dat de status niet blijft hangen
    let val = {
      ...vals,
      sensor_id: sensor.sensor._id,
    };
    changedatedepart(sensor.sensor.boeking._id, val).then((data) => {
      if (data.type === "ADD_GASTEN_VERBRUIK") {
        setError(false);
        reloadplaatsen();

        setVertrekwijzigen(false);
        setProgress(0);
        setVisible(false);
      } else {
        setError("Er is een fout opgetreden bij het toevoegen van de kWh");
      }
    });
  };

  const submitaddkwh = (vals) => {
    setLoadstatus("De nieuwe waarde wordt toegevoegd aan de boeking"); // Zorg ervoor dat de status niet blijft hangen
    let val = {
      ...vals,
      sensor_id: sensor.sensor._id,
    };
    addwkhboeking(sensor.sensor.boeking._id, val).then((data) => {
      if (data.type === "ADD_GASTEN_VERBRUIK") {
        setError(false);
        reloadplaatsen();
        setProgress(0);
        setAddnewkwh(false);
        setVisible(false);
      } else {
        setError("Er is een fout opgetreden bij het toevoegen van de kWh");
      }
    });
  };

  const submitverplaatsen = (vals) => {
    setLoadstatus("De reservering wordt verplaatst");
    let val = {
      ...vals,
      sensor_id: sensor.sensor._id,
    };

    verplaatsboeking(sensor.sensor.boeking._id, val)
      .then((data) => {
        if (data.type === "ADD_GASTEN_VERBRUIK") {
          setError(false);
          reloadplaatsen();
          setVisible(false);
          setProgress(0);
        } else {
          setError("Er is een fout opgetreden bij het toevoegen van de kWh");
        }
      })
      .catch((err) => {
        setError("Er is een onverwachte fout opgetreden");
      })
      .finally(() => {
        setLoadstatus(null); // Hiermee stopt de loader netjes zodra je API-call klaar is
      });
  };

  const schakelenmeteractie = (status) => {
    setLoadstatus("We schakelen de meter in/uit"); // Zorg ervoor dat de status niet blijft hangen
    schakelkwhnmeter(sensor.device_id, { schakelen: status })
      .then((data) => {
        if (data.type === "SENSOR_ERROR") {
          setError("Er is een fout opgetreden bij het schakelen van de meter");
          return;
        }
        setLoadstatus(false);
        reloadplaatsen();
        setError(false);
        setVisible(false);
        setProgress(0);
      })
      .catch((error) => {
        setError("Er is een fout opgetreden bij het schakelen van de meter");
        setLoadstatus(false);
        setProgress(0);
      });
  };
  console.log(sensor);
  return (
    <>
      <button className="small-box-footer btn" onClick={() => setVisible(true)}>
        <i className="fas fa-arrow-circle-right"></i> Ligplaats instellen
      </button>

      <Rodal visible={visible} onClose={() => setVisible(false)} className="rodal-md">
        <div className="card actionmodal">
          <div className="card-header">
            <h4 className="mb-0">{sensor.naam} instellen</h4>
          </div>

          <div className="box">
            {loadstatus && (
              <div className="overlay-loading">
                <p>{loadstatus}</p>
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated custom-progress-bar" style={{ width: `${progress}%` }}></div>
                </div>
              </div>
            )}

            {sensor?.sensor?.boeking?._id ? (
              <div className="p-4 d-flex flex-column">
                <strong className="mb-2">Deze ligplaats heeft een lopende boeking</strong>
                <div className="d-flex flex-column gap-2 mb-3">
                  <div className="d-flex">
                    <div className="me-2 fw-semibold">Geboekt door:</div>
                    <div>
                      {sensor?.sensor?.boeking?.gastinfo?.voornaam} {sensor?.sensor?.boeking?.gastinfo?.tussennaam} {sensor?.sensor?.boeking?.gastinfo?.achternaam}
                    </div>
                  </div>

                  {sensor?.sensor?.boeking?.gastinfo?.bootnaam && (
                    <div className="d-flex">
                      <div className="me-2 fw-semibold">Boot:</div>
                      <div>{sensor.sensor.boeking.gastinfo.bootnaam}</div>
                    </div>
                  )}

                  {sensor?.sensor?.boeking?.vertrek && (
                    <div className="d-flex">
                      <div className="me-2 fw-semibold">Vertrektijd:</div>
                      <div>{moment(sensor.sensor.boeking.vertrek).format("DD-MM-YYYY  HH:mm")}</div>
                    </div>
                  )}
                </div>
                <div className="d-flex gap-2 flex-wrap">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                      boekingafronden(sensor.sensor.boeking._id, {
                        boeking: sensor.sensor.boeking,
                        sensor_id: sensor.sensor._id,
                      })
                    }
                  >
                    <i className="fas fa-check me-2"></i>
                    Boeking afronden
                  </button>

                  <button type="button" className="btn btn-warning" onClick={() => setBoekingverplaatsen(!boekingverplaatsen)}>
                    <i className="fas fa-arrows-alt me-2"></i>
                    Boeking verplaatsen
                  </button>

                  {sensor?.sensor?.boeking?.kwh > 0 && (
                    <button type="button" className="btn btn-warning" onClick={() => setAddnewkwh(!addnewkwh)}>
                      <i className="fas fa-bolt me-2"></i>
                      Extra kWh kopen
                    </button>
                  )}

                  {sensor?.sensor?.boeking?.vertrek && (
                    <button type="button" className="btn btn-warning" onClick={() => setVertrekwijzigen(!vertrekwijzigen)}>
                      <i className="fas fa-calendar-alt me-2"></i>
                      Vertrekdatum wijzigen
                    </button>
                  )}

                  <Link type="button" className="btn btn-info text-white" to={{ pathname: "/gastenverbruik/kampeerplaats/" + sensor?.sensor?.boeking._id }}>
                    <i className="fas fa-eye me-2"></i>
                    Boeking bekijken
                  </Link>
                </div>
                {addnewkwh && <AddKWHForm onSubmit={submitaddkwh} />}
                {boekingverplaatsen && <VerplaatsenBoeking onSubmit={submitverplaatsen} plaatsen={plaatsen} />}
                {vertrekwijzigen && <ChangeVertrek onSubmit={submitvetrek} />}
              </div>
            ) : newgast ? (
              <div className="card p-4">
                <div className="d-flex justify-content-between align-items-center card-header secundairy">
                  <h3 className="m-0">Nieuwe gast toevoegen</h3>
                  <button type="button" className="btn btn-light border-0 p-2" onClick={() => setNewgast(false)}>
                    ✖
                  </button>
                </div>
                <Newgast onSubmit={submitgast} />
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="kwh-settings card p-4">
                <h4 className="card-header secundairy">Boek deze ligplaats</h4>
                <div className="d-flex align-items-end">
                  <div className="flex-grow-1 me-2">
                    <Field name="gast" sendsuggestion={(value) => setSoortgast(value)} type="email" minval="3" suggestievalue="email" suggestieid="_id" url="/api/admin/stamgegevens/find" validate={required({ msg: "Dit item is verplicht" })} component={AutosugformApi} label="Zoek op gast e-mail" />
                  </div>
                  <div>
                    <button type="button" className="btn btn-secondary" onClick={() => setNewgast(true)}>
                      Nieuwe gast toevoegen
                    </button>
                  </div>
                </div>
                {soortgast?.gastenprofiel && (
                  <div className="alert alert-primary my-2" role="alert">
                    {soortgast.gastenprofiel.naam && <strong>{soortgast.gastenprofiel.naam}</strong>}
                    {soortgast.gastenprofiel.beschrijving && <p>{soortgast.gastenprofiel.beschrijving}</p>}
                  </div>
                )}
                {soortgast?.gastenprofiel?.instellenkwh && <Field name="kwh" component={renderField} type="number" label="Aantal kWh instellen" validate={[required(), numericality()]} />}
                {soortgast?.gastenprofiel?.autouitcheck && <Field name="vertrek" component={DateTimeInput} type="input" timeIntervals="60" label="Vertrek datum verblijf" validate={required({ msg: "Dit item is verplicht" })} />}

                <div className="box-footer">
                  <button type="submit" className="btn btn-primary">
                    Instellen van de meter
                  </button>
                </div>
              </form>
            )}
            {error && <div className="alert alert-danger p-4">{error}</div>}
            <div className="box-body">
              <hr />
              <div className="meter-control">
                <h4>Meter acties</h4>
                <div className="box-footer d-flex gap-2">
                  <button type="button" className="btn btn-info" onClick={() => getstatus(sensor.device_id)}>
                    <i className="fas fa-info-circle me-2"></i>
                    Status meter
                  </button>

                  {sensor.sensor.electra.ingeschakeld ? (
                    <button type="button" className="btn btn-danger" onClick={() => schakelenmeteractie(false)}>
                      <i className="fas fa-power-off me-2"></i>
                      Meter uitschakelen
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary" onClick={() => schakelenmeteractie(true)}>
                      <i className="fas fa-plug me-2"></i>
                      Meter inschakelen
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Rodal>
    </>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "setupmeterinsert", // a unique identifier for this form
})(InstellenMeter);

const mapStateToProps = (state) => ({
  initialValues: state.form.setupmeterinsert?.initial || {},
});

export default connect(mapStateToProps, { getsensordevice_id, getsensorstatus, addgasten, addwkhboeking, verplaatsboeking, schakelkwhnmeter, changedatedepart })(ReduxFormSensorEdit);
