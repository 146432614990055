import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField } from "../../../../Components/Forms/renders";

const SensorEditForm = ({handleSubmit}) => {
 
  return (
    <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-12">
              <div className="mt-4 p-4 border">
                <strong>The thing network</strong>
                <Field name="ttn.url" type="input" component={renderField} label="Url" />
                <Field name="ttn.apikey" type="input" component={renderField} label="API Key" />
                <Field name="ttn.application" type="input" component={renderField} label="Application" />

                <strong>The thing network (MQQT)</strong>
                <Field name="mqqt.url" type="input" component={renderField} label="Url" />
                <Field name="mqqt.username" type="input" component={renderField} label="Username" />
                <Field name="mqqt.password" type="password" component={renderField} label="Password" />
                <Field name="mqqt.port" type="input" component={renderField} label="Application Port" />

              </div>
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Wijzigen" />
          </div>
        </div>
      </form>
    </div>
  );
};


const ReduxFormSensorEdit = reduxForm({
  form: "sensorinstellingedit", // a unique identifier for this form
})(SensorEditForm);

const mapStateToProps = (state) => ({
  initialValues: state.sensorinstellingen.item
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
